import { useState, useCallback, useEffect } from "react"
import { useSubmit, useLoaderData, useActionData } from "@remix-run/react"
import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node"
import { authenticator } from "@/services/auth.server"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"

import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "@/components/ui/input-otp"

import Logo from "~/components/layout/logo"
import CaberoLogo from "~/components/layout/caberoLogo"
import { Button } from "@/components/ui/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNfcSignal } from "@fortawesome/pro-light-svg-icons"
import { Text } from "@/components/text" 
import i18n from "~/i18n"

declare global {
    interface Window {
        NDEFReader: any
    }
}

interface DeviceType {
    type?: string;
    vendor?: string;
    model?: string;
    nfcLogin?: boolean;
    pinLogin?: boolean;
    microsoftLogin?: boolean;
}

export async function action({ request }: ActionFunctionArgs) {
    try {
        return await authenticator.authenticate("user-pass", request, {
            successRedirect: "/",
            throwOnError: true,
        })
    } catch (error) {
        return error
    }
}

export async function loader({ request }: LoaderFunctionArgs) {
    await authenticator.isAuthenticated(request, {
        successRedirect: "/",
    })

    return {
        loaded: true,
        logname: process.env.LOGNAME
    }
}

export default function Login() {
    const i18nPath = "route.auth.login"
    const { loaded, logname } = useLoaderData<typeof loader>()
    const error = useActionData()
    const [value, setValue] = useState("")
    const [authenticating, setAuthenticating] = useState(false)
    const [nfcAuth, setNfcAuth] = useState(false)
    const [disableNfcAuth, setdisableNfcAuth] = useState(false)
    const [devCounter, setDevCounter] = useState(0)
    
    
    const [deviceType, setDeviceType] = useState<DeviceType>({})
    const submit = useSubmit()

    const auth = useCallback((data: any, options: any) => {
        setAuthenticating(true)
        setNfcAuth(false)
        submit(data, options)

        setTimeout(() => {
            setAuthenticating(false)
        }, 10000)
    }, [value])

    useEffect(() => {
        setAuthenticating(false)
    }, [error, loaded])

    if (devCounter >= 10) {
        setDevCounter(0)
        alert(logname)
    }

    useEffect(() => {
        if (
            window.screen.availWidth + "x" + window.screen.availHeight === '360x640' &&
            window.navigator.userAgent.includes("Linux; Android")
        ) {
            setDeviceType({
                type: "mobile",
                vendor: 'honeywell',
                model: "CT45P",
                nfcLogin: true,
                pinLogin: true,
                microsoftLogin: false
            })
        }
        else if (
            window.screen.availWidth + "x" + window.screen.availHeight === '808x1436' &&
            window.navigator.userAgent.includes("Linux; Android")
        ) {
            setDeviceType({
                type: "pxe",
                vendor: 'allnet',
                model: "1",
                nfcLogin: false,
                pinLogin: true,
                microsoftLogin: false
            })
        }
        else {
            setDeviceType({
                type: "unknown",
                vendor: 'unknown',
                model: "unknown",
                nfcLogin: false,
                pinLogin: false,
                microsoftLogin: true
            })
        }
    }, [])

    return (
        <div className="flex justify-center items-center h-full flex-col">
            <CaberoLogo
                size={0.8}
                className="mb-2"
            />
            {!authenticating && (
                <>
                    <Card className="w-[300px] m-4 h-min">
                        <CardHeader className="text-center">
                            <Logo
                                onClick={() => {
                                    setDevCounter(devCounter + 1)
                                }}
                                size={1.5}
                                className="mb-2 flex justify-center"
                            />
                            <CardTitle><Text path={i18nPath}>title</Text></CardTitle>
                            <CardDescription><Text path={i18nPath}>description</Text></CardDescription>
                        </CardHeader>
                        <CardContent>
                            {(!nfcAuth) && (
                                <div className="flex flex-col items-center justify-between">
                                    {deviceType.nfcLogin && (
                                        <Button
                                            disabled={disableNfcAuth}
                                            variant={!disableNfcAuth ? "default" : "outline"}
                                            className="w-full block mb-4"
                                            onClick={async () => {
                                                try {
                                                    const ndef = new NDEFReader()
                                                    await ndef.scan()

                                                    ndef.addEventListener("readingerror", () => {})

                                                    setNfcAuth(true)

                                                    ndef.addEventListener("reading", ({ serialNumber } : any) => {
                                                        auth({
                                                            serial: serialNumber
                                                        },
                                                        {
                                                            method: "POST",
                                                            action: '/auth/nfc'
                                                        })
                                                    })
                                                } catch (error) {
                                                    setNfcAuth(false)
                                                    setdisableNfcAuth(true)
                                                }
                                            }}
                                        >
                                            <Text path={`${i18nPath}.button`}>keyFob</Text>
                                        </Button>
                                    )}
                                    {deviceType.microsoftLogin && (
                                        <Button
                                            className="w-full block"
                                            variant={(disableNfcAuth && !deviceType.nfcLogig) || !deviceType.nfcLogig ? "default" : "outline"}
                                            onClick={() => {
                                                auth({
                                                },
                                                {
                                                    method: "POST",
                                                    action: '/auth/microsoft'
                                                })
                                            }}
                                        >
                                            <Text path={`${i18nPath}.button`}>microsoft</Text>
                                        </Button>
                                    )}

                                    {deviceType.pinLogin && (
                                        <>
                                            <div className="flex items-center justify-center w-full my-5 text-xs font-mono">
                                                <Text path={i18nPath}>loginCode</Text>
                                            </div>

                                            <InputOTP
                                                maxLength={6}
                                                value={value}
                                                onChange={(value) => {
                                                    setValue(value)
                                                    if (value.length === 6) {
                                                        setValue("")
                                                        auth({
                                                            userNumber: value
                                                        },
                                                            {
                                                                method: "POST"
                                                            })
                                                    }
                                                }}
                                            >
                                                <InputOTPGroup>
                                                    <InputOTPSlot index={0} />
                                                    <InputOTPSlot index={1} />
                                                    <InputOTPSlot index={2} />
                                                </InputOTPGroup>
                                                <InputOTPSeparator />
                                                <InputOTPGroup>
                                                    <InputOTPSlot index={3} />
                                                    <InputOTPSlot index={4} />
                                                    <InputOTPSlot index={5} />
                                                </InputOTPGroup>
                                            </InputOTP>
                                        </>
                                    )}
                                </div>
                            )}
                            {nfcAuth && (
                                <div className="flex flex-col items-center justify-between">
                                    <FontAwesomeIcon
                                        icon={faNfcSignal}
                                        className="h-[180px] w-[180px] scale-100 transition-all animate-pulse"
                                    />
                                </div>
                            )}
                        </CardContent>
                    </Card>
                    <p
                        className="text-[11px] text-gray-500"
                    >
                        ©<Text path={i18nPath} options={{ 
                            year: new Date().getFullYear(),
                            company: "CABERO Wärmetauscher GmbH & Co. KG"
                        }}>copyright</Text>
                    </p>
                </>
            )}
        </div>
    )
}